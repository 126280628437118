import React from 'react';
import './App.css';
import LoginComponent from './LoginComponent';
import TimerStatus from './TimerStatus';

const io = require('socket.io-client');


function ErrorMessage(props) {
  if (props.message == null) {
    return null;
  } else {
    return (
      <h3 className="ErrorText">Error:<br/>{props.message}</h3>
    );
  }
}

class App extends React.Component {

  state = {
    socket: null,
    apiKey: null,
    loginError: null,
    currentTime: "---"
  }

  constructor(props) {
    super(props);
    this.onLoginError = this.onLoginError.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.onLoginSuccess = this.onLoginSuccess.bind(this);
    this.timerStatusComponent = React.createRef();
  }

  componentDidMount() {
    document.title = "Nova Dashboard"
    console.log("The window location is: ", window.location);
    console.log("Window hash is: ", window.location.hash);
    this.interval = setInterval(() => this.onIntervalElapsed(), 1000);    
    var urlParams = new URLSearchParams(window.location.hash);
    if (urlParams.has("#api_key")) {
      // TODO: not a secure way to pass the API key - implement proper auth system using tokens.
      let apiKey = urlParams.get("#api_key");
      console.log("Using API key from query params");
      window.history.pushState("", document.title, window.location.pathname + window.location.search);
      this.onLoginSuccess(apiKey);
    } else {
      console.log("Cookies:", document.cookie);
      let apiKeyCookie = document.cookie.replace(/(?:(?:^|.*;\s*)api_key\s*=\s*([^;]*).*$)|^.*$/, "$1");
      console.log("apiKeyCookie:", apiKeyCookie);
      if (apiKeyCookie.length > 0) {
        console.log("User was previously logged in, using cookie value");
        this.onLoginSuccess(apiKeyCookie);
      }      
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onIntervalElapsed() {
    this.setState({currentTime: new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', second:'2-digit'})});      
  }

  onLoginError(message) {
    this.setState({loginError: message});
  }

  render() {
    if (this.state.apiKey !== null) {
      return (
        <div className="App">
            <button id="LogoutButton" onClick={() => this.logOut()}>Logout</button>
            <div id="DashboardDiv">
              <h3 className="title">Current time:</h3>
              <h1>{this.state.currentTime}</h1>
              <h3 className="title">Timer:</h3>
              <TimerStatus ref={this.timerStatusComponent} />
            </div>
        </div>
      );
    } else {
      return (
        <div className="App">
            <div>
              <h1>Please log in</h1>
              <LoginComponent authServerUrl={this.props.authServerUrl} onLoginSuccess={x => this.onLoginSuccess(x) } onLoginError={this.onLoginError} />
              <ErrorMessage message={this.state.loginError}/>
            </div>
        </div>
      );
    }
  }

  onLoginSuccess(apiKey) {
    console.log("Login success");
    this.setState({apiKey: apiKey});
    document.cookie = "api_key="+apiKey;
    console.log(`Connecting to message relay at ${this.props.messageRelayUrl}`);
    const socket = io.connect(this.props.messageRelayUrl);

    socket.on('connect', () => {
      console.log("Connected to message relay");
      this.setState({socket: socket, loginError: null});
      this.sendMessage("subscribe", "timer_status", null);
      console.log("Subscribed to timer status messages");
    });

    socket.on('connect_error', (error) => {
      console.error("Error establishing connection to message relay", error);
      this.setState({loginError: "Error establishing connection to message relay"});
    });

    socket.on('message', (payload) => {
      console.log("Received message with payload: %o", payload);
      if (payload.topic === "timer_status") {
        console.log("Updating state of timerComponent with new payload data");
        this.timerStatusComponent.current.updateTimerState(payload.data);
      }
    });
  }

  sendMessage(command, topic, data) {
    let app = this;
    if (this.state.socket != null) {
      console.log(`Sending command '${command}'' on topic '${topic}' with data: %o`, data); 
      this.state.socket.emit("message", {"command": command, "api_key": this.state.apiKey, "topic": topic, "data": data}, function(response) {
        console.log("sendMessage: server response is:\n" + response);
        if (response === "invalid_api_key") {
          console.log("*** Invalid API key");
          app.setState({loginError: "Invalid API key"});
          app.logOut();
        }
      });
    } else {
      console.log("sendData: socket not yet connected");
    }
  }

  logOut() {
    console.log("Clearing cookie");
    this.setState({apiKey: null});
    document.cookie="api_key=;expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}

export default App;

import React from 'react';
import './TimerStatus.css';

class TimerStatus extends React.Component {

  state = {
    isStale: false
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.startTimer();
  }

  startTimer() {
    console.log("startTimer: starting timer");
    clearInterval(this.interval);
    this.interval = setInterval(() => this.onIntervalElapsed(), 10000);    
  }

  componentWillUnmount() {
    console.log("componentWillUnmount: clearing timer");
    clearInterval(this.interval);
  }

  onIntervalElapsed() {
    if (! this.state.isStale) {
      console.log("onIntervalElapsed: setting isStale flag on battery status");
      this.setState({isStale: true});      
    }
  }

  /** Invoked from the parent component to let us know that timer state has changed. **/
  updateTimerState(newTimerState) {
    console.log("updateTimerState: timerState changed to %o", this.state.timerState);
    this.setState({timerState: newTimerState});
    this.setState({isStale: false});
    this.setState({isUpdated: true});
    setTimeout(() => this.setState({isUpdated: false}), 500);
    this.startTimer();
  }

  render() {
    let divClass = this.state.isStale ? "staleStatus" : this.state.isUpdated ? "updated" : "liveStatus";
    if (this.state.timerState) {
      let totalSecondsRemaining = this.state.timerState.time_remaining;
      let minutesRemaining = Math.floor(totalSecondsRemaining/60)
      let secondsRemaining = totalSecondsRemaining % 60;
      let timeRemainingStr = minutesRemaining.toString().padStart(2, "0") + ":" + secondsRemaining.toString().padStart(2, "0");
      return (
        <div id="TimerStatus" className={divClass}>
          <h1>{timeRemainingStr}</h1>
        </div>
      ); 
    } else {
      return (
        <div id="TimerStatus" className={divClass}>
          <h1>- - -</h1>
        </div>
      ); 
    }
  }

}

export default TimerStatus;
